.analytics-date-picker-container {
    width: max-content;
    position: relative;
    display: inline-block;
    margin: 0px;
    padding: 12px;
    border-radius: 5px;
    min-height: 38px;
    border: solid 0.5px rgba(5, 5, 82, 0.2);
    align-items: center;
    transition: 0.1s ease;
}

.analytics-date-picker-container:hover {
    box-shadow: 0px 0px 20px 1px #564ef11d;
    cursor: pointer;
}

.analytics-date-picker-container:hover p {
    color: #554ef1;
}

.analytics-date-picker-container p {
    margin: 0;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.analytics-date-picker-container p img {
    margin: 0px 8.5px 0px 0px;
    object-fit: contain;
}

.analytics-date-picker-container p span {
    color: #554ef1;
    font-weight: 500;
    text-align: center;
}


.analytics-date-picker {
    position: absolute;
    background-color: white;
    padding: 8px;
    top: 42px;
    left: 0px;
    border-radius: 8px;
    z-index: 2;
    box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.1);
}