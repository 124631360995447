.funnel-comparison-tooltip {
    background-color: #01011C;
    border-radius: 5px;
    border: 0.5px solid #01011C;
    padding: 6px 12px;
    min-width: 175px;
    width: max-content;
    /* display: none; */
    transition: 0.2s ease-in;
    position: absolute;
    opacity: 0;
    top: -70px;
    left: 0;
}

.funnel-comparison-tooltip.active {
    display: block;
    z-index: 2;
    opacity: 100;
}

.funnel-comparison-tooltip div h3 {
    color: #FFF;
    font-family: Inter;
    font-size: 13px;
    margin-bottom: 2px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.funnel-comparison-tooltip div p {
    color: #FFF !important;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 6px;
}

.funnel_comparison_tooltip_tip {
    height: 14px;
    width: 14px;
    position: absolute;
    background-color: #01011C;
    transform: rotate(45deg);
    bottom: -8%;
    left: 17.5%;
}