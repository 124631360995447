.side_panel__main__container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 18;
    left: 0;
    top: 0;
    background: rgba(5, 5, 82, 0.25);
    cursor: pointer;
}

.side_panel__main__container.hidden {
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
}

.side_panel__body__container {
    width: 70%;
    height: 100vh;
    background-color: white;
    margin-left: auto;
    z-index: 19;
    cursor: initial;
    overflow: hidden;
}

.side_panel__close_button {
    position: absolute;
    right: 12px;
    top: 12px;
}