.stepwise-analytics-container {
  margin-top: 16px;
  width: 100%;
}

.stepwise-analytics-container-row,
.stepwise-analytics-container-col {
  margin: 0;
  padding: 0;
  height: 83vh;
}

.stepwise-analytics-container-col {
  scrollbar-width: thin;
  scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);
}

.stepwise-analytics-container-col::-webkit-scrollbar {
  width: 2px;
}

.stepwise-analytics-container-col:nth-child(2) .workflow-analytics-container {
  margin-left: 16px;
}

.stepwise-analytics-container-col-stats {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.workflow-analytics-container {
  border-radius: 5px;
  border: 0.5px solid rgba(5, 5, 82, 0.2);
  padding: 16px;
  height: 100%;
  min-height: 600px;
}

.workflow-analytics-header-container {
  display: flex;
  justify-content: space-between;
}

.workflow-analytics-header-container button {
  font-size: 12px;
  text-decoration: underline;
  color: #0000ff;
  transition: 0.1s ease;
}

.application-redirect-link-icon {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.workflow-analytics-header-container button:hover {
  color: rgb(2, 2, 116);
}

@media (max-width: 1200px) {
  .workflow-analytics-container {
    border-radius: 5px;
    border: 0.5px solid rgba(5, 5, 82, 0.2);
    margin-left: 0px;
    padding: 16px;
    height: 100%;
  }
}

.workflow-analytics-container h4 {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #050552;
}

.workflow-analytics-container p {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.6);
}

/* FLOW CHART */
.stepwise-flow {
  height: 100%;
  z-index: 2 !important;
}

.workflow-analytics-header-container h4 {
  display: flex;
  justify-content: center;
  align-items: center;
}