.condition-hover-tooltip {
    position: absolute;
    background-color: #01011C;
    border-radius: 5px;
    border: 0.5px solid #01011C;
    padding: 12px 12px;
    opacity: 0;
    transition: 0.1s ease;
    left: 140px;
    top: 5px;
}

.condition-hover-tooltip.active {
    opacity: 1;
}

.condition-hover-tooltip p {
    color: white;
    text-align: center;
    margin-bottom: 0;
    z-index: 2;
}

.condition-hover-tooltip-tip{
    height: 12px;
    width: 12px;
    position: absolute;
    background-color: #01011C;
    transform: rotate(45deg);
    left: -3%;
    bottom: 35%;
    z-index: 1;
}