.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.progress-container.disabled {
  cursor: not-allowed !important;
}

.progress-container .row {
  width: 100%;
}

.progress-container .label-col {
  display: flex;
  padding-left: 0;
  align-items: center;
}

.label-col .text_hover_showcase {
  font-family: 'Inter';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(5, 5, 82, 0.8);
  margin-bottom: 0;
}

.progress-container .progress-col {
  display: flex;
  justify-content: flex-end;
}

.progress-container .stats-progress-bar {
  height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.stats-progress-bar p {
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #443700;
  margin-bottom: 0 !important;
  margin-right: 2px !important;
}

.stepwise-analytics-stats-div {
  border-radius: 5px;
  border: 0.5px solid rgba(5, 5, 82, 0.2);
  padding: 16px;
  padding-bottom: 0;
  margin-bottom: 16px;
  min-height: 350px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.stepwise-analytics-stats-div:last-of-type {
  margin-bottom: 0 !important;
}

.stepwise-analytics-stats-div h4 {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #050552;
}

.stepwise-analytics-stats-div p {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 24px;
  color: rgba(5, 5, 82, 0.6);
}

.progress-div-no-data-div { 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.progress-div-no-data-div img {
  height: 160px;
}

.progress-div-no-data-div p {
  font-size: 14px;
  text-align: center;
}

.analytics-stats-div-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 4px;
  position: absolute;
  right: 8px;
  bottom: 0;
}

.analytics-stats-div-footer p {
  margin: 0;
  color: rgba(5, 5, 82, 0.60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.analytics-stats-pagination-left img, .analytics-stats-pagination-right img {
  height: 16px;
  width: 16px;
  line-height: 16px;
}

.analytics-stats-pagination-left:disabled, .analytics-stats-pagination-right:disabled {
  cursor: not-allowed;
}

.analytics-stats-pagination-right img {
  transform: rotate(180deg);
}

.progress-container:hover {
  cursor: pointer;
}