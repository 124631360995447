.comparison_custom_node_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80px;
    margin: 24px 0;
}

.comparison_custom_node_circle {
    border-radius: 50%;
    border: 1px solid #000;
    padding: 8px;
}

.comparison_custom_node_label {
    text-align: center !important;
    margin-top: 8px;
    margin-bottom: 2px;
}

.comparison_custom_node_delta {
    font-weight: bold;
    color: #4e4e4e;
    font-family: 'Inter';
    font-size: 12px;
}