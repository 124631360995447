.custom-report-container {
  font-family: Inter;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
}

.custom-report-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.custom-report-header-container > h3 {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  text-align: left;
  color: #050552;
  margin: 0;
}

.custom-report-header-filters {
  margin-left: 12px;
}

.custom-report-body-container {
  margin-top: 20px;
  height: 100%;
  width: 100%;
}

.custom-report-body-container.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-report-body-container > iframe {
  width: 100%;
  height: 100%;
}

.custom-report-body-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 285px;
}

.custom-report-body-error-container > h5 {
  margin-top: 24px;
  color: rgba(5, 5, 82, 0.8);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.custom-report-body-error-container > p {
  color: rgba(5, 5, 82, 0.8);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
