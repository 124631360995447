.comparison_layout, .comparison-no-data-container {
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comparsion_layout_flow {
    width: 100%;
}

.comparison-no-data-container img {
    height: 180px;
    width: 180px;
}

.comparison-no-data-container p {
    font-size: 16px !important;
}