.label_node_output.approve {
    border-color: #8ae1b4 !important;
    background-color: #e3fcef;
}

.label_node_output.approve .label_node_top .label_node_heading {
    color: #006644;
}

.label_node_output.decline {
    border-color: #ffcec1 !important;
    background-color: #ffebe6;
}

.label_node_output.decline .label_node_top .label_node_heading {
    color: #bf2600;
}

.label_node_output.manualReview {
    border-color: #ffe19b !important;
    background-color: rgba(255, 225, 155, 0.35);
}

.label_node_output.manualReview .label_node_top .label_node_heading {
    color: #9a4c24;
}

.label_node_output.user_cancelled {
    border-color: #959595 !important;
    background-color: #d9dcde;
}

.label_node_output.user_cancelled .label_node_top .label_node_heading {
    color: #5c5c5c;
}