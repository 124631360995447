.analytics-landing-div {
    width: 40%;
    text-align: center;
}

.analytics-landing-div img {
    width: 364px;
    height: 240px;
    margin-bottom: 28px;
}

.analytics-landing-div h5 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #050552;
}

.analytics-landing-div p {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: rgba(5, 5, 82, 0.6);
    margin-bottom: 0;
}

.analytics-landing-div button {
    margin-top: 24px;
    width: 88px;
    height: 36px;
    flex-grow: 0;
    text-align: center;
    border-radius: 6px;
    border: solid 1px rgba(5, 5, 82, 0.1);
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: rgba(5, 5, 82, 0.8);
}