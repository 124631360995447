.hover-details-container {
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
  border: solid 0.5px rgba(5, 5, 82, 0.1);
  background-color: white;
  min-width: 202px;
  width: 100%;
}

.stepwise-popover {
  border: none;
}

.stepwise-popover .popover-arrow {
  display: none;
}

.hover-details-header-div {
  padding: 12px;
  border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);
  margin-bottom: 12px;
}

.hover-details-header-div h4 {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.8);
  margin-bottom: 0;
}

.hover-details-data-col,
.hover-details-data-row {
  margin: 0;
  padding: 0;
}

.hover-details-data-col  {
    padding: 0 12px 16px 12px;
}

.hover-details-data-col p {
  margin-bottom: 0;
}

.details-data-title p {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.6);
}

.details-data-value p {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.8);
}

.downspace {
  margin-bottom: 10px;
}