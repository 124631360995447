.modal-overlay {
    background-color: rgba(1, 1, 28, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 21;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay-fullscreen {
    position: fixed !important;
    background-color: rgba(1, 1, 28, 0.8);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 21;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay:hover {
    cursor: pointer;
}

.modal-container {
    background-color: white;
    border-radius: 8px;
    height: 95%;
    width: 95%;
    display: flex;
    flex-direction: column;
}

.modal-close-button {
    position: absolute;
    right: 30px;
    top: 20px;
    z-index: 13;
    font-size: 20px;
}

.modal-body {
    flex-grow: 1;
}