.debug-mode-filter-input {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 13px;
}

.debug-mode-filter-input::placeholder, .debug-mode-filter-input input::placeholder {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
}

.debug-mode-filter-input input {
    font-size: 13px;
    padding: 10px;
}

.analytics-filters-apply-button {
    height: 38px;
    border-radius: 8px;
    background: #554EF1;
    padding: 8px 16px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.analytics-filters-refresh-button {
    height: 36px;
    width: 40px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.analytics-filters-refresh-button img {
    height: 24px;
    width: 24px;
}

.analytics-filters-refresh-button:disabled {
    cursor: not-allowed;
}

.analytics-filters-apply-button:hover {
    box-shadow: 0px 0px 8px 4px #564ef11d;
    cursor: pointer;
}