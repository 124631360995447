.toggle_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle_container p {
    margin: 0;
    font-size: 14px;
    color: #050552;
    margin-right: 8px;
}

.toggle {
    position: relative;
    padding: 5px 3px;
    width: 34px;
    height: 18px;
    background-color: #ccc;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &.active {
        background-color: #01BF83;
    }

    &:hover {
        cursor: pointer;
    }
}


.slider {
    cursor: pointer;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: white;

    &.active {
        margin-left: auto;
    }
}

