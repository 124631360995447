.analytics__tooltip {
    position: absolute;
    display: none;
    top: 0%;
    left: 0%;
    border-radius: 5px;
    background: #01011C;
    padding: 8px;
}

.analytics__tooltip p {
    color: white !important;
    margin-bottom: 0;
    font-family: "Inter";
    font-size: 13px;
}

.analytics__tooltip.active {
    display: block;
}