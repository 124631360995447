.funnel-comparsion-container {
    border-radius: 5px;
    border: 0.5px solid rgba(5, 5, 82, 0.2);
    padding: 16px;
    height: max-content;
}

.funnel-comparsion-container h4 {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050552;
}

.funnel-comparsion-container p {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.6);
}

.comparisons-loading-div {
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comparisons-landing-container {
    height: 560px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}