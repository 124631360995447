.analytics-loading-spinner {
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
}

.analytics-loading-spinner p {
  margin-top: 12px;
  font-size: 13px !important;
  text-align: center !important;
}
