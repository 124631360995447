.accordion_container {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px !important;
  font-family: 'Inter' !important;
  color: rgba(5, 5, 82, 0.8);
  border: 0.5px solid rgba(5, 5, 82, 0.15);
  border-radius: 8px;
}

.accordion_button {
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid rgba(23, 23, 167, 0.05);
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #050552CC;
}

.accordion_button:hover {
  cursor: grab;
  background-color: #05055208;
}

.accordion_button img {
  transition: transform 0.3s ease-in-out;
  margin-right: 7px;
  width: 5px;
}

.accordion_button img.open {
  transform: rotate(90deg);
}

.accordion_content {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  max-height: 0;
}

.accordion_content.show {
  max-height: 500px; /* Set a maximum height */
}

.accordion_inner_content {
  padding: 10px;
  font-size: 13px !important;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #050552CC;
  border-top: 1px solid rgba(5, 5, 82, 0.05);
}

.accordion_content img {
  margin-bottom: 10px;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .accordion_button {
    font-size: 14px;
  }
}

.count_text {
  color: rgba(5, 5, 82, 0.8);
  font-size: 15px !important;
  font-weight: 600;
}

.arrow {
  transition: transform 0.3s ease-in-out;
  margin-right: 7px;
  color: rgb(40, 35, 142);
  font-size: 13px;
  font-weight: 200;
}

.open {
  transform: rotate(90deg);
}

#know_more_accordion_content_div {
  margin-top: 10px;
  width: 100%;
  height: auto;
}

#know_more_accordion_content_div > img {
  width: 100%;
}

#know_more_accordion_content_div > p {
  font-size: 14px;
}
