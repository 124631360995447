.dropdown__container {
    margin: 0px;
    position: relative;
    display: inline-block;
}

.dropdown__label {
    padding: 8px 8px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    background-color: white;
    align-items: center;
    transition: 0.1s ease;
    border: solid 0.5px rgba(5, 5, 82, 0.2);
    font-size: 14px;
    transition: 0.1s ease;
    -webkit-appearance: none;
}

.dropdown__label:hover {
    box-shadow: 0px 0px 20px 1px #564ef11d;
    cursor: pointer;
}

.dropdown__label p {
    margin: 0;
    font-size: 14px;
}

.dropdown__label p span {
  color: #554ef1;
  font-weight: 500;
}

.dropdown__options {
    display: none;
    position: absolute;
    margin-top: 4px;
    border-radius: 5px;
    border: solid 0.5px rgba(5, 5, 82, 0.2);
    z-index: 2;
    background-color: white;
}

.dropdown__options div {
    padding: 8px;
}

.dropdown__options div:hover {
    color: #554ef1;
    cursor: pointer;
}

.dropdown__options p {
    margin: 0;
    font-size: 14px;
}


.dropdown__option__active {
    display: block;
    max-height: 180px;
    overflow-y: scroll;
}

.dropdown__options__hidden {
    display: none;
}