.App {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.loader {
        background-color: white;
    }
}

$audit_button_border_color: rgba(5, 5, 82, 0.1);
$audit_button_box_shadow_color: rgba(85, 78, 241, 0.1);
$audit_button_hover_border_color: rgba(85, 78, 241, 0.1);
$audit_button_active_border_color: #554ef1;
$audit_button_color: #554ef1;
$subheading_font_size: 14px;
$audit_button_text_color: rgba(5, 5, 82, 0.8);

button {
    background: none;
    outline: inherit;
    color: inherit;
    border: none;
    font: inherit;
    text-align: left;
    padding: 0;
}

@mixin audit_button() {
    background-color: white;
    padding: 10px 12px;
    border: 1px solid $audit_button_border_color;
    border-radius: 5px;
    font-size: $subheading_font_size;
    font-weight: normal;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    color: $audit_button_text_color;
    white-space: nowrap;

    &:hover:not(.disabled) {
        cursor: pointer;
        box-shadow: 0 0 15px 0 $audit_button_box_shadow_color;
        border: solid 1px $audit_button_hover_border_color;
        color: $audit_button_color;

        >img {
            filter: brightness(0) invert(33%) sepia(55%) saturate(7149%) hue-rotate(238deg) brightness(98%) contrast(93%) saturate(100%);
        }
    }

    &.active,
    &.selected {
        box-shadow: 0 0 15px 0 $audit_button_box_shadow_color;
        border: solid 1px $audit_button_active_border_color;
        color: $audit_button_color;

        >img {
            filter: brightness(0) invert(33%) sepia(55%) saturate(7149%) hue-rotate(238deg) brightness(98%) contrast(93%) saturate(100%);
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}