.flag-stats-row {
    margin-top: 16px;
}

.no-data-div, .loading-sankey-div {
    width: 100%;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-data-div img {
    height: 186px;
    width: 186px;
}

.no-data-div p {
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.4);
}

.sankey-metrics-spinner {
    margin-top: 8px;
}

.funnel-analytics-landing-div {
    width: 100%;
    height: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
}