.layout-parent-div {
  height: 100vh;
  width: 100%;
  transition: 0.2s ease;
  display: flex;
}

.layout-component-div {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
