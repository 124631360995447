.home-parent-div {
  width: 100%;
  display: flex;
}

.height-chart {
  height: 300px !important;
}

.stepwise-header-container {
  padding: 24px 24px 0 24px;
}

.stepwise-header-container {
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: 4px;
  z-index: 2;
}

.stepwise-content-container {
  padding: 0 24px 24px 24px;
}

.home-content-div {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(4, 4, 73, 0.409) rgba(4, 4, 73, 0);
}

.home-content-div::-webkit-scrollbar {
  width: 1px;
}

.home-content-div h3 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  text-align: left;
  color: #050552;
}

.analytics-data-div {
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  border: solid 0.5px rgba(5, 5, 82, 0.2);
}

.analytics-data-div h5 {
  margin: 0 58px 6px 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #050552;
}

.analytics-data-div p {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.6);
}

.analytics-filters-div {
  width: max-content;
  margin: 0px;
  padding: 8px 12px;
  border-radius: 5px;
  border: solid 0.5px rgba(5, 5, 82, 0.2);
  display: flex;
  height: 38px;
  align-items: center;
  transition: 0.1s ease;
}

.analytics-filters-div:hover {
  box-shadow: 0px 0px 20px 1px #564ef11d;
  cursor: pointer;
}

.analytics-filters-div:hover p {
  color: #554ef1;
}

.analytics-filters-div p {
  margin: 0;
  font-family: 'Inter';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.8);
  display: flex;
  justify-content: center;
}

.analytics-filters-div p img {
  margin: 0px 8.5px 0px 0px;
  object-fit: contain;
}

.analytics-filters-div p span {
  color: #554ef1;
  font-weight: 500;
}

.analytics-chart-div {
  width: 100%;
  margin-bottom: 40px;
}

.analytics-data-col,
.analytics-data-row {
  margin: 0;
  padding: 0;
}

.analytics-data-row {
  margin-top: 16px;
}

.analytics-data-col {
  height: 120px;
  padding: 12px;
  text-align: center;
  position: relative;
}

.analytics-data-col::after {
  content: '';
  height: 48px;
  width: 0.5px;
  position: absolute;
  right: 0;
  top: 35%;
  background-color: #cdcddd;
}

.analytics-data-col:last-child::after {
  height: 0;
}

.analytics-data-col div {
  width: max-content;
  text-align: left;
  margin: auto;
}

.analytics-data-col p {
  width: 100%;
  height: max-content;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  color: rgba(5, 5, 82, 0.4);
}

.info-icon {
  width: 14px;
  height: 14px;
  margin-left: 6px;
  object-fit: contain;
}

.analytics-data-col h5 {
  width: 100%;
  flex-grow: 0;
  font-family: 'Inter';
  font-size: 48px;
  height: max-content;
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: left;
  color: rgba(5, 5, 82, 0.6);
}

.analytics-data-col:last-child {
  border-right: none;
}

.funnel-popover {
  border: none;
}

.funnel-popover .popover-body-text {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  white-space: pre-line;
  padding: 6px 8px;
  background-color: #01011c;
  color: #ffffff;
  border-radius: 8px;
}

.funnel-popover .popover-arrow::after {
  border-top-color: #01011c !important;
}

.no-data-div {
  height: 440px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-data-div img {
  height: 186px;
  width: 186px;
}

.no-data-div p {
  font-family: Inter;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(5, 5, 82, 0.4);
}

.analytics-filters-appid {
  width: max-content;
  margin: 24px 0px 12px 0px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  transition: 0.1s ease;
}

.popover .popover-body {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  white-space: pre-line;
  padding: 6px 8px;
  background-color: #01011c;
  color: #ffffff;
  border-radius: 8px;
}

.popover {
  position: fixed;
  border-width: 0
}

.popover .popover-arrow {
  display: none;
}

.stepwise-apply-button {
  padding: 8px;
  width: 140px;
  text-align: center;
  background-color: #050552;
  border-radius: 4px;
  color: white;
}

.stepwise-landing-div {
  height: 560px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}