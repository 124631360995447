.layout {
    height: 100%;
}

.layout-parent {
    height: calc(100% - 59px);
}

.nodes__module {
    position: relative;
    background-color: '#ffffff';
    width: 150px;
    display: flex;
    justify-content: center;
    height: 70px;
    white-space: nowrap;
    z-index: -10 !important;
}

.label_node_top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: rgba(5, 5, 82, 0.7);
}

.label_node_icon {
    display: flex;
}
.label_node_icon svg {
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;
    height: 14px;
    width: 14px;
}

.label_node_icon svg g {
    fill: rgba(5, 5, 82, 0.7);;
}

.info-icon {
    width: 14px;
    height: 14px;
    margin-left: 6px;
    object-fit: contain;
    display: flex;
}

.nodes__module_output {
    position: relative;
    border-radius: 100px;
    border: solid 0px rgba(5, 5, 82, 0.2);
    display: flex;
    width: 140px;
    height: 10px;
    justify-content: center;
    white-space: nowrap;
    background-color: '#ffffff';
    z-index: -10 !important;
}

.condition {
    width: 200px; /* Can be in percentage also. */
    display: flex;
    justify-content: center;
}

.condition-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.diamond {
    position: absolute;
    width: 43px;
    height: 43px;
    display: block;
    border-radius: 5px;
    border: solid 1px rgba(5, 5, 82, 0.2);
    word-break: break-all;
    transform: rotate(45deg);
    margin-left: -1.5px;
    margin-top: -1.5px;

}

.outer {
    width: 40px;
    height: 40px;
    margin: 5px;
}

.label_node {
    position: absolute;
    display: flex;
    padding: 12px;
    flex-direction: column;
    border-radius: 5px;
    border: solid 1px rgba(5, 5, 82, 0.2) !important; 
    margin: auto;
}

.label_node_drop_off_container {
    position: absolute;
    left: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.label_node_drop_off_container p {
    margin-bottom: 0;
    margin-left: 12px;
    font-weight: bold;
    display: flex;
    color: #bf2600;
}

.label_node_drop_off {
    height: 36px;
    width: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 1px #ffcec1;
    background-color: #ffebe6;
}

.label_node_drop_off p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
}

.label_drop_off_icon {
    margin-left: 0 !important;
    height: 20px;
    width: 20px;
}

.label_node_drop_off_hr {
    width: 40px;
    height: 0px;
    left: -50%;
}

.label_node_drop_off_arrow:before {
    content:'';
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: rgba(5, 5, 82, 0.4) transparent;
    display: block;
    width: 0;
    z-index: 1;
    left: -20%;
    top: 0.5px;
    position: relative;
    transform: rotate(270deg);
}

.label_node_drop_off_hr {
    width: 40px;
    height: 0px;
    left: -50%;
}

.label_node_drop_off_arrow:before {
    content:'';
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: rgba(5, 5, 82, 0.4) transparent;
    display: block;
    width: 0;
    z-index: 1;
    left: -20%;
    top: 0.5px;
    position: relative;
    transform: rotate(270deg);
}

.label_node_output {
    position: absolute;
    display: flex;
    width: auto;
    padding: 12px;
    flex-direction: column;
    border-radius: 100px;
    border: solid 1px rgba(5, 5, 82, 0.2) !important; 
    margin: auto; 
}

.label_node_heading {
    height: 15px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(5, 5, 82, 0.7);
}

.label_node_stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.label_node_stats_body {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
}

.label_node_stats_heading {
    font-family: Inter;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.8);
}

.label_node_stats_data {
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.6);
}

.label_edge_red {
    border-radius: 100px;
    background-color: #ffebe6;
}

.label_edge_green {
    border-radius: 100px;
    background-color: #f2fcf9;
}

.figure {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-top: 100px;
    transform: rotate(67.5deg) skewX(45deg) scaleY(cos(45deg));
}

.rhombus {
    margin: 100px auto;
    border: 1px solid gray;
}

.react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus, .react-flow__node-default.selectable:focus-visible, .react-flow__node-input.selectable.selected, .react-flow__node-input.selectable:focus, .react-flow__node-input.selectable:focus-visible, .react-flow__node-output.selectable.selected, .react-flow__node-output.selectable:focus, .react-flow__node-output.selectable:focus-visible, .react-flow__node-group.selectable.selected, .react-flow__node-group.selectable:focus, .react-flow__node-group.selectable:focus-visible {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08) !important;
}

.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
    border: solid 0px rgba(5, 5, 82, 0.2) !important; 
}

.react-flow__handle {
    opacity: 0 !important;
}

.react-flow__attribution {
    opacity: 0 !important;
}

.react-flow__node-default.selectable:hover, .react-flow__node-input.selectable:hover, .react-flow__node-output.selectable:hover, .react-flow__node-group.selectable:hover {
    box-shadow: none !important;
}

.react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus, .react-flow__node-default.selectable:focus-visible, .react-flow__node-input.selectable.selected, .react-flow__node-input.selectable:focus, .react-flow__node-input.selectable:focus-visible, .react-flow__node-output.selectable.selected, .react-flow__node-output.selectable:focus, .react-flow__node-output.selectable:focus-visible, .react-flow__node-group.selectable.selected, .react-flow__node-group.selectable:focus, .react-flow__node-group.selectable:focus-visible {
    box-shadow: none !important;
}

/* EMPTY STATE */

.stepwise-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 500px;
}

.stepwise-empty-state img {
    height: 200px;
}

.stepwise-empty-state p {
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(5, 5, 82, 0.4);
}

.stepwise-start-icon {
    height: 16px;
}

.goto_highlight {
    background-color: yellow;
}

.show_hidden_edges_icon {
    height: 12px;
    width: 12px;
    margin-left: 12px;
    transition: 0.1s ease;
}

.show_hidden_edges_icon:hover {
    color: blue;
    cursor: pointer;
}