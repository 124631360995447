.module__side_panel__header__container {
    padding: 20px 24px;
    border-bottom: 1px solid rgba(5, 5, 82, 0.1);
}

.module__side_panel__title {
    display: flex;
    align-items: center;
}

.module__side_panel__header__container h1 {
    color: #050552;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0;
}

.module__side_panel__header__container p {
    margin-top: 14px;
    margin-bottom: 0;
    color: rgba(5, 5, 82, 0.80) !important;
    font-family: 'Inter';
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
}

.module__side_panel__header__container p span {
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 4px;
    padding: 2px 8px;
    background-color: rgba(5, 5, 82, 0.05);
    margin: 0 12px;
}

.module__side_panel__body__container {
    padding: 16px 24px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.module__side_panel__filter_dropdown_container {
    display: flex;
}

.module__side_panel__filter_dropdown_container button {
    border-radius: 8px;
    background: #554EF1;
    padding: 8px 16px;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    margin-left: 12px;
    line-height: 20px;
}

.module__side_panel__filter_dropdown {
    border: 1px solid rgba(5, 5, 82, 0.1);
    width: max-content;
    border-radius: 6px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.module__side_panel__filter_dropdown p {
    font-family: 'Inter';
    font-size: 13px !important;
    color: rgba(5, 5, 82, 0.8) !important;
    margin-bottom: 0;
    line-height: 20px !important;
}

.module__side_panel__filter_dropdown p span {
    color: rgba(85, 78, 241, 1);
    font-weight: 500;
}

.module__side_panel__filter_icon {
    margin-left: 12px;
    transition: 0.2s ease;
}

.module__side_panel__filter_icon.rotate {
    transform: rotate(180deg);
}

.module__side_panel__filter_options_container {
    position: absolute;
    top: 60px;
    padding: 8px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 6px;
    background-color: white;
    height: 232px;
    width: 440px;
    z-index: 18;
}

.module__side_panel__filter_options_search_container {
    margin-bottom: 4px;
    border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);
    padding: 8px;
    display: flex;
    align-items: center;
}

.module__side_panel__filter_options_search_container input {
    border: none;
    outline: none;
    margin-left: 8px;
    width: 100%;
    font-size: 12px;
}

.module__side_panel__filter_options_search_container input::placeholder {
    color: rgba(5, 5, 82, 0.4);
    font-size: 12px;
    font-family: 'Inter';
}

.module__side_panel__filter_list_container {
    overflow-y: scroll;
    height: 176px;
    scrollbar-width: none;
}

.module__side_panel__filter_option_container {
    padding: 9.5px 8px;
    transition: 0.2s ease;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.module__side_panel__filter_option_container:last-of-type {
    margin-bottom: 0;
}

.module__side_panel__filter_option_container:hover {
    cursor: pointer;
    background: rgba(5, 5, 82, 0.05);
}

.module__side_panel__filter_option_container.selected {
    background: rgba(85, 78, 241, 0.10);
}

.module__side_panel__filter_option_container p {
    margin-bottom: 0;
    color: rgba(5, 5, 82, 0.80) !important;
    font-family: 'Inter';
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
}

.module__side_panel__filter_option_container p:first-of-type {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.module__side_panel__body__info_container {
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(85, 78, 241, 0.20);
    background: rgba(85, 78, 241, 0.05);
    padding: 8px 12px;
    margin-top: 16px;
}

.module__side_panel__body__info_container p {
    margin-bottom: 0;
    color: rgba(5, 5, 82, 0.80) !important;
    font-family: 'Inter';
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.module__side_panel__body__info_container p span {
    margin-left: auto;
    color: #554EF1;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: 0.1s ease;
}

.module__side_panel__body__info_container p span:hover {
    cursor: pointer;
}

.module__side_panel__body__info_icon {
    margin-right: 4px;
}

.module__side_panel__transactions_table_container {
    margin-top: 8px;
    margin-bottom: 16px;
}

.module__side_panel__transactions_table {
    border: 1px solid rgba(85, 78, 241, 0.10);
    border-radius: 6px;
    overflow: hidden;
}

.module__side_panel__transactions_table_header_container,
.module__side_panel__transactions_table_row_container {
    display: flex;
}

.module__side_panel__transactions_table_header_cell,
.module__side_panel__transactions_table_row_cell {
    padding: 20px;
    display: flex;
    align-items: center;
}

.module__side_panel__transactions_table_header_cell {
    background-color: #0505520f;
}

.module__side_panel__transactions_table_row_container {
    border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);
    transition: 0.1s ease;
    position: relative;
}

.module__side_panel__transactions_table_row_container.disabled {
    cursor: default;
    background: #F2F2F6;
    color: #8D8DAE;
}

.module__side_panel__transactions_table_row_container:hover {
    background-color: #0505520a;
    cursor: pointer;
}

.module__side_panel__transactions_table_header_cell.transaction_id,
.module__side_panel__transactions_table_row_cell.transaction_id {
    flex: 2;
}

.module__side_panel__transactions_table_header_cell.created_at,
.module__side_panel__transactions_table_row_cell.created_at {
    flex: 1
}

.module__side_panel__transactions_table_header_cell.status,
.module__side_panel__transactions_table_row_cell.status {
    flex: 1
}

.module__side_panel__transactions_table_header_cell h5,
.module__side_panel__transactions_table_row_cell p {
    font-size: 14px !important;
    font-family: 'Inter';
    color: #050552 !important;
    margin-bottom: 0 !important;
}

.module__side_panel__transactions_table_header_cell h5 {
    font-weight: 600;
}

.module__side_panel__transactions_table_body_container {
    min-height: 500px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.application__status {
    font-size: 12px;
    margin: 2px;
    padding: 0.5em 1.75em;
    border-radius: 20px;
}

.application__status.declined {
    background-color: #ffebe6;
    color: #bf2600;
}

.application__status.review {
    background-color: #ffe19b59;
    color: #9a4c24;
}

.application__status.dropped {
    background-color: #f4f5f7;
    color: rgba(5, 5, 82, 0.6);
}

.application__status.applying {
    background-color: #deebff;
    color: #0747a6;
}

.application__status.approved {
    background-color: #e3fcef;
    color: #006644;
}

.module__side_panel__transactions_table__loading_overlay {
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 20;
}

.know_more_modal {
    z-index: 1000 !important;
    width: 40% !important;
    min-height: 580px;
    max-height: 580px;
    max-width: 50vw;
    height: auto !important;
    overflow: auto; 
    padding-bottom: 20px;
    position: relative;
  }
  
  .know_more_modal_header {
    padding: 8px 16px;
    border-bottom: 1px solid #0505520D;
  }
  
  .know_more_modal_header h5 {
    font-size: 18px !important;
    margin-bottom: 0 !important;
    padding: 10px;
  }
  
  .know_more_modal_body {
    padding: 8px 16px;
    max-height: calc(90vh - 100px);
    overflow-y: auto;
  }
  
  