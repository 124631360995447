.search_select_dropdown_container {
  display: flex;
  position: relative;
}

.search_select_dropdown_container button {
  border-radius: 8px;
  background: #554ef1;
  padding: 8px 16px;
  color: #fff;
  text-align: center;
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  margin-left: 12px;
  line-height: 20px;
}

.search_select_dropdown {
  width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search_select_dropdown p {
  color: #554ef1;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.24px;
  margin: 0;
}

.search_select_dropdown:hover p {
  text-decoration-line: underline;
}

.search_select_dropdown p span {
  color: rgba(85, 78, 241, 1);
  font-weight: 500;
}

.search_select_dropdown_icon {
  margin-left: 4px;
  transition: 0.2s ease;
}

.search_select_dropdown_icon.rotate {
  transform: rotate(180deg);
}

.search_select_options_container {
  position: absolute;
  top: calc(100% + 20px);
  padding: 8px;
  border: 1px solid rgba(5, 5, 82, 0.1);
  border-radius: 6px;
  background-color: white;
  height: 232px;
  width: 440px;
  z-index: 18;
}

.search_select_options_search_container {
  margin-bottom: 4px;
  border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);
  padding: 8px;
  display: flex;
  align-items: center;
}

.search_select_options_search_container input {
  border: none;
  outline: none;
  margin-left: 8px;
  width: 100%;
  font-size: 12px;
}

.search_select_options_search_container input::placeholder {
  color: rgba(5, 5, 82, 0.4);
  font-size: 12px;
  font-family: 'Inter';
}

.search_select_list_container {
  overflow-y: scroll;
  height: 176px;
  scrollbar-width: none;
}

.search_select_option_container {
  padding: 9.5px 8px;
  transition: 0.2s ease;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.search_select_option_container:last-of-type {
  margin-bottom: 0;
}

.search_select_option_container:hover {
  cursor: pointer;
  background: rgba(5, 5, 82, 0.05);
}

.search_select_option_container.selected {
  background: rgba(85, 78, 241, 0.1);
}

.search_select_option_container p {
  margin-bottom: 0;
  color: rgba(5, 5, 82, 0.8) !important;
  font-family: 'Inter';
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
}

.search_select_option_container p:first-of-type {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
